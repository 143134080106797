export type ThemeType = {
  colors: {
    [key: string]: string,
  },
  size: {
    xs: string,
    s: string,
    m: string,
    l: string,
    xl: string,
    xxl: string,
  },
}
export const BASE_THEME: ThemeType = {
  colors: {
    "bg": "#ECECEC",
    "fg": "#050505",
  },
  size: {
    xs: "4px",
    s: "8px",
    m: "16px",
    l: "32px",
    xl: "64px",
    xxl: "128px",
  },
};

