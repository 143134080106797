import { useState } from "react";
import { PaintingWFC, PaintingWFCProps } from "./PaintingWFC";

const PaintingWFCWithControls = () => {
  const [width, setWidth] = useState(512);
  const [height, setHeight] = useState(512);
  const [stateSize, setStateSize] = useState<[number, number]>([32, 32]);
  const [sendIntermediateStates, setSendIntermediateStates] = useState(true);

  const [finalWfcProps, setFinalWfcProps] = useState<PaintingWFCProps | null>(null);

  const handleSubmit = () => {
    setFinalWfcProps({
      stateSize,
      width,
      height,
      sendIntermediateStates,
    });
  };

  return (
    <>
      {
        finalWfcProps && <PaintingWFC
          width={finalWfcProps.width}
          height={finalWfcProps.height}
          stateSize={finalWfcProps.stateSize}
          sendIntermediateStates={finalWfcProps.sendIntermediateStates}
          renderType="mosaic"
        />
      }
      <div>
        Width:
        <input

          type="number"
          value={width}
          onChange={(e) => {
            const width = e.target.valueAsNumber;
            setWidth(width);
          }}
        />
        Height:
        <input
          type="number"
          value={height}
          onChange={(e) => {
            const height = e.target.valueAsNumber;
            setHeight(height);
          }}
        />
        State size X:
        <input
          type="number"
          value={stateSize[0]}
          onChange={(e) => {
            const stateSizeX = e.target.valueAsNumber;
            setStateSize([stateSizeX, stateSize[1]]);
          }}
        />
        State size Y:
        <input
          type="number"
          value={stateSize[1]}
          onChange={(e) => {
            const stateSizeY = e.target.valueAsNumber;
            setStateSize([stateSize[0], stateSizeY]);
          }}
        />
        Send intermediate states:
        <input
          type="checkbox"
          checked={sendIntermediateStates}
          onChange={(e) => {
            const sendIntermediateStates = e.target.checked;
            setSendIntermediateStates(sendIntermediateStates);
          }}
        />

        <button onClick={handleSubmit}>Generate</button>
      </div>
    </>
  )

}

export default PaintingWFCWithControls;