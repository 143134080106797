import { MouseEventHandler, useEffect, useState } from "react";
import { useMemo } from "react";
import { AlgoName } from "./generate";
import { imageDataToDataURL } from "./generate/common";
import { PaintingImg } from "./PaintingCommon";
import { requestWorker } from "./requestWorker";

type PaintingProps = {
  seed: string,
  palette: string,
  magnification?: number,
  width: number,
  height: number,
  algo: AlgoName,
  onClick?: MouseEventHandler<HTMLImageElement>
}

const Painting = ({ seed, palette, magnification = 1, width = 16, height = 16, onClick, algo }: PaintingProps) => {
  const [paintingContent, setPaintingContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  // Subscribe to the worker's message event
  useEffect(() => {
    const worker = requestWorker();
    setLoading(true); // Reset loading state

    const handleMessage = (event: MessageEvent) => {
      if (event.data.width === width &&
        event.data.height === height &&
        event.data.seed === seed &&
        event.data.palette === palette &&
        event.data.algo === algo) {
        setPaintingContent(imageDataToDataURL(event.data.imageData));
        setLoading(false);
      }
    };

    worker.addEventListener("message", handleMessage);

    // Send message to worker
    worker.postMessage({
      type: "painting",
      algo,
      width,
      height,
      seed,
      palette,
    });

    return () => {
      worker.removeEventListener("message", handleMessage);
      // worker.terminate();
    }
  }, [seed, width, height, palette, algo]);

  return (
    <PaintingImg
      src={paintingContent || ""}
      alt={`A beautiful painting in palette of ${palette}`}
      width={width * magnification}
      height={height * magnification}
      onClick={onClick}
      aspectRatio={useMemo(() => width / height, [width, height])}
    />
  )
}

export default Painting;