import { getXY, WFCDefinitionList } from "./solveWFC";

function loadImage(src: string): Promise<[string, HTMLImageElement]> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    img.onload = () => resolve([src, img]);
    img.src = src;
  });
}

/**
 * Take a WFC step result, access the required images, compose them and return the result.
 * @param wfcDefinitions A list of WFC definitions with .src of the images
 * @param stateSize Tuple containing the size of the state
 * @param state The state to compose
 */
export async function composeWFCState(
  wfcDefinitions: WFCDefinitionList,
  stateSize: [number, number],
  state: (string | undefined)[],
): Promise<string> {
  const imagesToLoad = new Set<string>(
    state.map(cell => cell === undefined ? "" : (wfcDefinitions[cell].src ?? ""))
  );

  imagesToLoad.delete(""); // Type system funtime
  const imagePromises = Array.from(imagesToLoad).map(src => loadImage(src));
  const loadedImages = await Promise.all(imagePromises);

  // Init canvas
  const canvas = document.createElement("canvas");
  canvas.width = stateSize[0] * loadedImages[0][1].width;
  canvas.height = stateSize[1] * loadedImages[0][1].height;

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Could not get canvas context");
  }

  // Draw images
  for (let i = 0; i < state.length; i++) {
    const [x, y] = getXY(stateSize, i);
    if (state[i] === undefined) {
      continue;
    } else {
      const [_, requestedImage] = loadedImages[
        loadedImages.findIndex(img => wfcDefinitions[state[i] as string].src === img[0])
      ];
      ctx.drawImage(requestedImage, x * requestedImage.width, y * requestedImage.height);
    }
  }

  return canvas.toDataURL("image/png");
}