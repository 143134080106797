export type Palette = number[][];
export type PaletteName = "garish" | "gb" | "4blue";

type PaletteList = {
  [key in PaletteName]: Palette;
}

const palettes: PaletteList = {
  // Garish 8-color palette
  "garish": [
    [255, 255, 255],
    [255, 255, 0],
    [0, 255, 255],
    [0, 255, 0],
    [255, 0, 255],
    [255, 0, 0],
    [0, 0, 255],
    [0, 0, 0]
  ],
  // The GameBoy color palette
  "gb": [
    [155, 188, 15],
    [139, 172, 15],
    [48, 98, 48],
    [15, 56, 15],
  ],
  // 4 shades of cyan
  "4blue": [
    [66, 176, 245],
    [73, 157, 209],
    [53, 107, 140],
    [31, 66, 79],
  ],
};

export default palettes;