import styled from "styled-components";

interface PaintingImgProps {
  readonly aspectRatio: number;
}

export const PaintingImg = styled.img<PaintingImgProps>`
  image-rendering: pixelated;
  width: ${props => props.width ? props.width + "px" : "100%"};
  height: ${props => props.height ? props.height + "px" : "100%"};
  aspect-ratio: ${props => props.aspectRatio};
`;
