import { Palette } from "../palettes";

export type PNGString = string;

export type CanvasGeneratorFunction = (seed: string, palette: Palette, width: number, height: number) => ImageData;

export function putPixel(
  imageData: ImageData,
  x: number,
  y: number,
  color: number[],
): void {
  const index = (y * imageData.width + x) * 4;
  imageData.data[index + 0] = color[0];
  imageData.data[index + 1] = color[1];
  imageData.data[index + 2] = color[2];
  imageData.data[index + 3] = 255;
}
export function imageDataToDataURL(imagedata: ImageData): string {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  canvas.width = imagedata.width;
  canvas.height = imagedata.height;
  if (ctx) {
    ctx.putImageData(imagedata, 0, 0);

    return canvas.toDataURL();
  } else {
    throw new Error('Could not get canvas context');
  }
}
export function makeNonce(length: number): string {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return `${text}-${Date.now()}`;
}