// eslint-disable-next-line import/no-webpack-loader-syntax
import RendererWorker from "worker-loader!./RendererWorker";

const workers: RendererWorker[] = new Array(navigator.hardwareConcurrency || 1)
  .fill(null)
  .map(() => new RendererWorker());
let workerIndex = 0;

export const requestWorker = () => {
  const worker = workers[workerIndex];
  workerIndex = (workerIndex + 1) % workers.length;
  return worker;
};
