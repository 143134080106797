import { WFCDefinitionList } from "./generate/wfc/solveWFC";

export const pipes: WFCDefinitionList = {
  "1": {
    // Horizontal W-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-1.png",
  },
  "2": {
    // Vertical S-N
    northGroup: 1,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-2.png",
  },
  "3": {
    // Horizontal W-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-3.png",
  },
  "4": {
    // Horizontal W-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-4.png",
  },
  "5": {
    // Horizontal W-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-5.png",
  },
  "6": {
    // Vertical S-N
    northGroup: 1,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-6.png",
  },
  "7": {
    // Elbow S-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-7.png",
  },
  "8": {
    // Elbow S-W
    northGroup: 0,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 1,
    src: "pipes/pipes-8.png",
  },
  "9": {
    // Elbow N-W
    northGroup: 1,
    eastGroup: 0,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-9.png",
  },
  "10": {
    // Elbow N-E
    northGroup: 1,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 0,
    src: "pipes/pipes-10.png",
  },
  "11": {
    // Horizontal W-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-11.png",
  },
  "12": {
    // Vertical S-N
    northGroup: 1,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-12.png",
  },
  "13": {
    // Vertical S-N
    northGroup: 1,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-13.png",
  },
  "14": {
    // Vertical S-N
    northGroup: 1,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-14.png",
  },
  "15": {
    // Horizontal W-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-15.png",
  },
  "16": {
    // Vertical S-N
    northGroup: 1,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-16.png",
  },
  "17": {
    // Junction
    northGroup: 1,
    eastGroup: 1,
    southGroup: 1,
    westGroup: 1,
    src: "pipes/pipes-17.png",
  },
  "18": {
    // Junction
    northGroup: 1,
    eastGroup: 1,
    southGroup: 1,
    westGroup: 1,
    src: "pipes/pipes-17.png",
  },
  "19": {
    // Elbow S-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-19.png",
  },
  "20": {
    // Elbow S-W
    northGroup: 0,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 1,
    src: "pipes/pipes-20.png",
  },
  "21": {
    // Elbow N-E
    northGroup: 1,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 0,
    src: "pipes/pipes-21.png",
  },
  "22": {
    // Elbow N-W
    northGroup: 1,
    eastGroup: 0,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-22.png",
  },
  "23": {
    // Elbow S-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-23.png",
  },
  "24": {
    // Elbow S-W
    northGroup: 0,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 1,
    src: "pipes/pipes-24.png",
  },
  "25": {
    // Elbow N-W
    northGroup: 1,
    eastGroup: 0,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-25.png",
  },
  "26": {
    // Elbow N-E
    northGroup: 1,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 0,
    src: "pipes/pipes-26.png",
  },
  "27": {
    // Junction
    northGroup: 1,
    eastGroup: 1,
    southGroup: 1,
    westGroup: 1,
    src: "pipes/pipes-27.png",
  },
  "28": {
    // Junction
    northGroup: 1,
    eastGroup: 1,
    southGroup: 1,
    westGroup: 1,
    src: "pipes/pipes-28.png",
  },
  // "29": {
  //     // Ball-end north
  //     northGroup: 1,
  //     eastGroup: 0,
  //     southGroup: 0,
  //     westGroup: 0,
  //     src: "pipes/pipes-29.png",
  // },
  // "30": {
  //     // Ball-end south
  //     northGroup: 0,
  //     eastGroup: 0,
  //     southGroup: 1,
  //     westGroup: 0,
  //     src: "pipes/pipes-30.png",
  // },
  // "31": {
  //     // Ball-end south
  //     northGroup: 0,
  //     eastGroup: 0,
  //     southGroup: 1,
  //     westGroup: 0,
  //     src: "pipes/pipes-31.png",
  // },
  // "32": {
  //     // Ball-end north
  //     northGroup: 1,
  //     eastGroup: 0,
  //     southGroup: 0,
  //     westGroup: 0,
  //     src: "pipes/pipes-32.png",
  // },
  // "33": {
  //     // Ball-end west
  //     northGroup: 0,
  //     eastGroup: 0,
  //     southGroup: 0,
  //     westGroup: 1,
  //     src: "pipes/pipes-33.png",
  // },
  // "34": {
  //     // Ball-end east
  //     northGroup: 0,
  //     eastGroup: 1,
  //     southGroup: 0,
  //     westGroup: 0,
  //     src: "pipes/pipes-34.png",
  // },
  // "35": {
  //     // Ball-end south
  //     northGroup: 0,
  //     eastGroup: 0,
  //     southGroup: 1,
  //     westGroup: 0,
  //     src: "pipes/pipes-35.png",
  // },
  // "36": {
  //     // Ball-end north
  //     northGroup: 1,
  //     eastGroup: 0,
  //     southGroup: 0,
  //     westGroup: 0,
  //     src: "pipes/pipes-36.png",
  // },
  "37": {
    // Horizontal W-E
    northGroup: 0,
    eastGroup: 1,
    southGroup: 0,
    westGroup: 1,
    src: "pipes/pipes-37.png",
  },
  "38": {
    // Vertical S-N
    northGroup: 1,
    eastGroup: 0,
    southGroup: 1,
    westGroup: 0,
    src: "pipes/pipes-38.png",
  },
  // "39": {
  //     // Empty
  //     northGroup: 0,
  //     eastGroup: 0,
  //     southGroup: 0,
  //     westGroup: 0,
  //     src: "pipes/pipes-39.png",
  // },
}