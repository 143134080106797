import seedrandom from 'seedrandom';
import { CanvasGeneratorFunction } from './common';

export const chaosToImageData: CanvasGeneratorFunction = (
  seed: string,
  palette: number[][],
  width: number,
  height: number,
) => {

  const rng = seedrandom(seed);

  const data = new Uint8ClampedArray(width * height * 4);
  // Loop over every single pixel in the image, pick a random color from the
  // palette and set the appropriate pixel in `data`. Alpha is always 255.
  for (let i = 0; i < width * height; i++) {
    // Pick a random color from `palette` using `rng`
    const color = palette[Math.floor(rng() * palette.length)];
    data[i * 4 + 0] = color[0];
    data[i * 4 + 1] = color[1];
    data[i * 4 + 2] = color[2];
    data[i * 4 + 3] = 255;
  }

  return new ImageData(data, width, height);
}