import seedrandom from "seedrandom";
import { pickIntBetweenWithRNG, pickWithRNGFrom } from "../utils";
import { CanvasGeneratorFunction, putPixel } from "./common";

type Point = [number, number];

function evolveShape(
  iterations: number,
  width: number,
  height: number,
  startingPosition: Point,
  rng: () => number,
): Point[] {
  const finalPoints: Point[] = [
    startingPosition,
  ];

  // Iterate `iterations` times
  for (let i = 0; i < iterations; i++) {
    // For every iteration, loop over the shape (finalPoints) and create a list of neighboring points that are not already in the list
    const newPoints: Point[] = [];

    for (const point of finalPoints) {
      const [x, y] = point;
      const neighbors: Point[] = [
        [x + 1, y],
        [x - 1, y],
        [x, y + 1],
        [x, y - 1],
        // Diagonals
        [x + 1, y + 1],
        [x - 1, y + 1],
        [x + 1, y - 1],
        [x - 1, y - 1],
      ];

      for (const neighbor of neighbors) {
        const [neighborX, neighborY] = neighbor;
        if (
          neighborX >= 0 && neighborX < width
          && neighborY >= 0 && neighborY < height
          && !finalPoints.some(([x, y]) => x === neighborX && y === neighborY)
          && !newPoints.some(([x, y]) => x === neighborX && y === neighborY)
        ) {
          newPoints.push(neighbor);
        }
      }
    }

    // Pick a random point from newPoints and add it to finalPoints
    if (newPoints.length > 0) {
      finalPoints.push(pickWithRNGFrom(rng, newPoints) as Point);
    }
  }

  return finalPoints;
}

type PetriParams = {
  splotchCountMin: number,
  splotchCountMax: number,
  splotchEvolutionIterationCountMin: number,
  splotchEvolutionIterationCountMax: number,
}

const petriMaker = (
  {
    splotchCountMin,
    splotchCountMax,
    splotchEvolutionIterationCountMin,
    splotchEvolutionIterationCountMax,
  }: PetriParams
) => {
  return (
    (
      seed: string,
      palette: number[][],
      width: number,
      height: number,
    ) => {
      const rng = seedrandom(seed);
      const data = new Uint8ClampedArray(width * height * 4);
      // Fill the canvas with a single background color at random from the palette
      const backgroundColor = pickWithRNGFrom(rng, palette);
      for (let i = 0; i < data.length; i += 4) {
        data[i + 0] = backgroundColor[0];
        data[i + 1] = backgroundColor[1];
        data[i + 2] = backgroundColor[2];
        data[i + 3] = 255;
      }
      const imageData = new ImageData(data, width, height);

      const countSplotches = pickIntBetweenWithRNG(rng, splotchCountMin, splotchCountMax);

      for (let i = 0; i < countSplotches; i++) {
        const newPoints = evolveShape(
          pickIntBetweenWithRNG(rng, splotchEvolutionIterationCountMin, splotchEvolutionIterationCountMax),
          width,
          height,
          [
            pickIntBetweenWithRNG(rng, 0, width),
            pickIntBetweenWithRNG(rng, 0, height),
          ],
          rng,
        );

        const color = pickWithRNGFrom(rng, palette);
        for (const point of newPoints) {
          const [x, y] = point;
          putPixel(imageData, x, y, color);
        }
      }

      return imageData;
    }
  )
}

export const petriToImageData: CanvasGeneratorFunction = petriMaker({
  splotchCountMin: 20,
  splotchCountMax: 100,
  splotchEvolutionIterationCountMin: 40,
  splotchEvolutionIterationCountMax: 200,
});