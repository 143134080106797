import Graphemer from "graphemer";

const splitter = new Graphemer();

export const LETTER_VALUES: string[] = splitter.splitGraphemes("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789" +
  "!@#$%^&*()_-=+ " +
  "אבגדהוזחטיכלמנסעפצקרשת" +
  "ףךןםץ" +
  "αβγδεζηθικλμνξοπρστυφχψω" +
  "₪₵¢£¥₩₨₹₺₾₿₱" +
  "💿💾🕹🖥🖨💻🎥☎️📞📺🎚⏰⏳🔌🪔🧯💰🪙💎🪛🔧🔨🚬🛡⚱️🧿🪬🔭🔬🩻⚗️💉🩸🧬" +
  "🧺🧪🌡🧹🚽🧻🪠🚰🚿🛁🧽🪒🪥🧼🪣🧴🛎🔑🛋🪑🚪🛏🧸🪆🖼🪞🪟🛍🛒🎁🎈🎏🎀🪄🪅🎊🎉" +
  "❤️🧡💛💚💙💜🖤🤍🤎" +
  "👁👄💄🦾🤚💩☠️👽😤😎🐲🌳🪵🍆🍒🍌🍉🍇🍗🍖" +
  "🐈🦢💥🌍🌈🌥☃️💦💧☂️🫐🥥" +
  "←↑→↓"
)
  ;
export function stringToHex(s: string): string {
  return splitter.splitGraphemes(s)
    .map(grapheme => LETTER_VALUES.indexOf(grapheme).toString(16).padStart(2, '0'))
    .join('');
}

export function hexToString(hex: string): string {
  let result = "";
  for (let i = 0; i < hex.length; i += 2) {
    result += LETTER_VALUES[parseInt(hex.slice(i, i + 2), 16)];
  }
  return result;
}

export function sanitizeSeedString(seed: string): string {
  const graphemes = splitter.splitGraphemes(seed);
  return graphemes.filter(g => LETTER_VALUES.includes(g)).join("");
}

export function arrayToString(array: number[]): string {
  return array.map(n => LETTER_VALUES[n]).join("");
}

export function pickWithRNGFrom<T>(rng: () => number, items: T[]): T {
  return items[Math.floor(rng() * items.length)];
}

/**
 * 
 * @param rng The random number generator
 * @param min The lower bound of the range
 * @param max  The upper bound of the range (inclusive)
 * @returns 
 */
export function pickIntBetweenWithRNG(rng: () => number, min: number, max: number): number {
  return Math.floor(rng() * (max - min + 1)) + min;
}