type EdgeGroupNames = 'northGroup' | 'eastGroup' | 'southGroup' | 'westGroup';

export type WFCDefinition = {
  northGroup?: number,
  eastGroup?: number,
  southGroup?: number,
  westGroup?: number,
  src: string;
}

export type WFCOptions = {
  limitIterations?: number,
  verbose?: boolean,
  allowIdenticalNeighbors?: boolean,
}

export const DEFAULT_WFC_OPTIONS = {
  limitIterations: Infinity,
  verbose: false,
  allowIdenticalNeighbors: true,
};

export type WFCDefinitionList = {
  [key: string]: WFCDefinition
};

export function getXY(
  stateSize: [number, number],
  i: number
) {
  const [x, y] = [i % stateSize[0], Math.floor(i / stateSize[0])];
  return [x, y];
}

function getIndex(
  stateSize: [number, number],
  x: number,
  y: number,
): number | undefined {
  if (x < 0 || x >= stateSize[0] || y < 0 || y >= stateSize[1]) {
    return undefined;
  }
  return y * stateSize[0] + x;
}

type MaybeIndex = number | undefined;
/**
 * Get the neighbors of a cell in a grid
 * @param stateSize A tuple containing the size of the grid
 * @param i The cell's position in the grid as a flat array
 * @returns A list of neighbors' positions in the array starting from north, going clockwise
 */
function getNeighborIndices(
  stateSize: [number, number],
  i: number
): [MaybeIndex, MaybeIndex, MaybeIndex, MaybeIndex] {
  const [x, y] = getXY(stateSize, i);
  return [
    getIndex(stateSize, x, y - 1),
    getIndex(stateSize, x + 1, y),
    getIndex(stateSize, x, y + 1),
    getIndex(stateSize, x - 1, y)
  ];
}

function calculatePossibleEdgeGroups(
  wfcDefinitions: WFCDefinitionList,
  stateSize: [number, number],
  state: (string | undefined)[],
  allowIdenticalNeighbors: boolean,
): (string[][]) {
  // This generates is an array containing the entropies (i.e. list of options)
  // for every single cell in the state. If a cell's array has length of 1, it
  // has been solved. If it is 0, it has no options (i.e. error). Otherwise it
  // will have a list of options as specified in the WFC definition provided.
  const possibleEdgeGroups: string[][] = state.map((cell, i) => {
    if (cell !== undefined) {
      return [cell];
    } else {
      const [
        northNeighbor,
        eastNeighbor,
        southNeighbor,
        westNeighbor
      ] = getNeighborIndices(stateSize, i);

      const requirements: {
        northGroup?: number,
        eastGroup?: number,
        southGroup?: number,
        westGroup?: number,
      } = {};

      const neighborTypes = new Set<string>();

      if (northNeighbor !== undefined) {
        const neighborType = state[northNeighbor];
        if (neighborType !== undefined) {
          requirements.northGroup = wfcDefinitions[neighborType].southGroup;
          neighborTypes.add(neighborType);
        }
      }
      if (eastNeighbor !== undefined) {
        const neighborType = state[eastNeighbor];
        if (neighborType !== undefined) {
          requirements.eastGroup = wfcDefinitions[neighborType].westGroup;
          neighborTypes.add(neighborType);
        }
      }
      if (southNeighbor !== undefined) {
        const neighborType = state[southNeighbor];
        if (neighborType !== undefined) {
          requirements.southGroup = wfcDefinitions[neighborType].northGroup;
          neighborTypes.add(neighborType);
        }
      }
      if (westNeighbor !== undefined) {
        const neighborType = state[westNeighbor];
        if (neighborType !== undefined) {
          requirements.westGroup = wfcDefinitions[neighborType].eastGroup;
          neighborTypes.add(neighborType);
        }
      }
      // Return all keys in `wfcDefinitions` whose values matches
      // `reuiqrements`. i.e. all possible tile options.
      return Object.keys(wfcDefinitions)
        .filter((tileId) => {
          const tile = wfcDefinitions[tileId];
          if (!allowIdenticalNeighbors && neighborTypes.has(tileId)) {
            return false;
          }
          return Object.keys(requirements).every(key => {
            return requirements[key as EdgeGroupNames] === tile[key as EdgeGroupNames];
          });
        })
    }
  });

  return possibleEdgeGroups;
}

export function solveWFC(
  wfcDefinitions: WFCDefinitionList,
  stateSize: [number, number],
  state: (string | undefined)[],
  options: WFCOptions = {},
): (string | undefined)[] {
  if (stateSize[0] * stateSize[1] !== state.length) {
    throw new Error('State size does not match state length');
  }

  const { limitIterations, verbose, allowIdenticalNeighbors } = { ...DEFAULT_WFC_OPTIONS, ...options };

  let entropies: string[][];
  let iteration = 0;
  do {
    iteration++;
    if (verbose) { console.log(`Running iteration ${iteration}`); }

    entropies = calculatePossibleEdgeGroups(wfcDefinitions, stateSize, state, allowIdenticalNeighbors);

    // Now it's time to find the cell with the least entropy that isn't 1
    let lowestEntropy = Infinity;
    let lowestEntropyIndex = -1;
    for (let i = 0; i < entropies.length; i++) {
      if (entropies[i].length <= 1) {
        continue;
      }

      if (entropies[i].length < lowestEntropy) {
        if (entropies[i].length === 2) {
          lowestEntropy = entropies[i].length;
          lowestEntropyIndex = i;
          break;
        }
        lowestEntropy = entropies[i].length;
        lowestEntropyIndex = i;
      }
    }

    if (lowestEntropyIndex === -1) {
      continue;
    }

    // Pick a tile for the lowest entropy cell
    const lowestTileId = entropies[lowestEntropyIndex][Math.floor(Math.random() * entropies[lowestEntropyIndex].length)];
    // And assign it
    state[lowestEntropyIndex] = lowestTileId;

  } while (
    entropies.some(entropy => entropy.length > 1)
    && iteration < limitIterations
  );

  // Clean up the state with collapsed cells that may not have been updated
  for (let i = 0; i < state.length; i++) {
    if (state[i] === undefined && entropies[i].length === 1) {
      state[i] = entropies[i][0];
    }
  }

  // Our wave has collapsed, yay!
  return state;
}