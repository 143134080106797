import { CanvasGeneratorFunction, } from "./common";
import { chaosToImageData } from "./chaos";
import { lindenmayerToImageData } from "./lindenmayer";
import { petriToImageData } from "./petri";

export type AlgoName = "lindenmayer" | "chaos" | "petri";
export type AlgoMapping = {
  [key in AlgoName]: CanvasGeneratorFunction;
}

/**
 * List of all algos with rendering functions
 */
export const ALGOS: AlgoMapping = {
  "lindenmayer": lindenmayerToImageData,
  "chaos": chaosToImageData,
  "petri": petriToImageData,
};
