import styled from "styled-components";
import { WFCDefinitionList } from "./generate/wfc/solveWFC"
import { PaintingImg } from "./PaintingCommon";

type PaintingMosaicProps = {
  stateSize: [number, number],
  state: (string | undefined)[],
  width: number,
  height: number,
  wfcDefinitions: WFCDefinitionList,
}

interface PaintingMosaicWrapperProps {
  stateSize: [number, number];
  width: number,
  height: number,
}

const PaintingMosaicWrapper = styled.div<PaintingMosaicWrapperProps>`
  display: grid;
  // The grid is equal cells, stateSize[0] columns and stateSize[1] rows.
  grid-template-columns: repeat(${props => props.stateSize[0]}, 1fr);
  grid-template-rows: repeat(${props => props.stateSize[1]}, 1fr);
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

export const PaintingMosaic = ({
  stateSize,
  state,
  width,
  height,
  wfcDefinitions,
}: PaintingMosaicProps) => {
  return <PaintingMosaicWrapper
    stateSize={stateSize}
    height={height}
    width={width}
  >
    {
      state.map((cell, index) => {
        if (cell === undefined) {
          // Nothing to paint
          return <div
            style={{
              height: height / stateSize[1],
              width: width / stateSize[0],
            }}
          />
        } else {
          return <PaintingImg
            key={index}
            width={width / stateSize[0]}
            height={height / stateSize[1]}
            src={wfcDefinitions[cell].src || ""}
            aspectRatio={width / height}
          />
        }
      })
    }
  </PaintingMosaicWrapper>
}