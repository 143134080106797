import { useState } from "react";
import { hexToString, sanitizeSeedString, stringToHex } from "./utils";

type SeedTextEditProps = {
    seed: string,
    onChange: (seed: string) => void,
}

const SeedTextEdit = ({seed, onChange}: SeedTextEditProps) => {
    const [hexView, setHexView] = useState(true);
    
    return (
        <div className="SeedTextEdit">
            Seed:
            <div className="SeedTextEdit__controls">
                <button
                    onClick={() => setHexView(true)}
                    disabled={hexView}
                >
                    Hex
                </button>
                <button
                    onClick={() => setHexView(false)}
                    disabled={!hexView}
                >
                    String
                </button>
            </div>
            {
                hexView ? (
                    <textarea
                        value={stringToHex(seed)}
                        onChange={(e) => onChange(hexToString(e.target.value))}
                    />
                ) : (
                    <textarea
                        value={seed}
                        onChange={(e) => onChange(sanitizeSeedString(e.target.value))}
                    />
                )
            }
        </div>
    )
}

export default SeedTextEdit;
