import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BASE_THEME, ThemeType } from './constants';
import PaintingWithControls from './PaintingWithControls';
// import SiteWrapper from './SiteWrapper';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import PaintingWFCWithControls from './PaintingWFCWithControls';


const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  body {
    background: ${props => props.theme.colors.bg};
    color: ${props => props.theme.colors.fg};
  }
`;

function App() {
  return (
    <ThemeProvider theme={BASE_THEME}>
      <Router>
        <GlobalStyle />
        <Routes>
          <Route path="/debug" element={<PaintingWithControls />} />
        </Routes>
        <Routes>
          <Route path="/debug-wfc" element={<PaintingWFCWithControls />} />
        </Routes>

      </Router>
    </ThemeProvider>
  );
}

export default App;
