import seedrandom from 'seedrandom';
import { pickIntBetweenWithRNG, pickWithRNGFrom } from '../utils';
import { CanvasGeneratorFunction, putPixel } from './common';

// F appears several times so it gets picked more often
const VALID_COMMANDS = ['F', 'F', 'F', '>', '<', '[', ']'];

/**
 * Run a lindenmayer system
 * @param axiom The base string to be used as the axiom
 * @param rules A map of characters to strings, where the key is the character to be replaced and the value is the replacement string
 * @param iterations Number of iterations to perform
 * @returns A string of the result of the L-system
 */
function runLindenmayer(
  axiom: string,
  rules: { [key: string]: string },
  iterations: number
) {
  let result = axiom;
  for (let i = 0; i < iterations; i++) {
    result = result.replace(/[A-Z]/g, match => rules[match] || match);
  }
  return result;
}

/**
 * Draws a lindenmayer system result directly to an image data object in-place (no new array is created)
 * @param axiom The lindenmayer system axiom containing only `VALID_COMMANDS`
 * @param color an RGB color array
 * @param imageData The image data to draw to (in place)
 */
function drawLindenmayer(
  axiom: string,
  color: number[],
  startingX: number,
  startingY: number,
  startingHeading: number,
  imageData: ImageData,
): void {

  let x = startingX;
  let y = startingY;
  let heading = startingHeading;
  let positionStack: [number, number][] = [];

  for (let i = 0; i < axiom.length; i++) {
    const command = axiom[i];
    switch (command) {
      case 'F':
        // Move forward depending on heading
        switch (heading) {
          // Move depending on heading, wrapping around the edges of the canvas
          // Heading is one of 0, 1, 2, 3, 4, 5, 6, 7
          // 0 being east, 1 being south-east, 2 being south, 3 being south-west, 4 being west, 5 being north-west, 6 being north, 7 being north-east
          case 0:
            x = (x + 1) % imageData.width;
            break;
          case 1:
            x = (x + 1) % imageData.width;
            y = (y + 1) % imageData.height;
            break;
          case 2:
            y = (y + 1) % imageData.height;
            break;
          case 3:
            x = (x - 1 + imageData.width) % imageData.width;
            y = (y + 1) % imageData.height;
            break;
          case 4:
            x = (x - 1 + imageData.width) % imageData.width;
            break;
          case 5:
            x = (x - 1 + imageData.width) % imageData.width;
            y = (y - 1 + imageData.height) % imageData.height;
            break;
          case 6:
            y = (y - 1 + imageData.height) % imageData.height;
            break;
          case 7:
            x = (x + 1) % imageData.width;
            y = (y - 1 + imageData.height) % imageData.height;
            break;
        }
        putPixel(imageData, x, y, color);
        break;
      case '>':
        heading = (heading + 1) % 8;
        break;
      case '<':
        heading = (heading + 7) % 8;
        break;
      case '[':
        positionStack.push([x, y]);
        break;
      case ']':
        [x, y] = positionStack.pop() || [0, 0];
        break;
    }
  }
}

type LindenmayerParams = {
  axiomCountMin: number,
  axiomCountMax: number,
  axiomInitialLength: number,
  axiomIterationCountMin: number,
  axiomIteratinCountMax: number,
  ruleCountMin: number,
  ruleCountMax: number,
  ruleValueLengthMin: number,
  ruleValueLengthMax: number,

}

const lindenmayerMaker = (
  {
    axiomCountMin,
    axiomCountMax,
    axiomInitialLength,
    axiomIterationCountMin,
    axiomIteratinCountMax,
    ruleCountMin,
    ruleCountMax,
    ruleValueLengthMin,
    ruleValueLengthMax,
  }: LindenmayerParams
) => {

  return (
    seed: string,
    palette: number[][],
    width: number,
    height: number,
  ) => {


    const data = new Uint8ClampedArray(width * height * 4);
    const imageData = new ImageData(data, width, height)
    const rng = seedrandom(seed);

    // Fill the canvas with a single background color at random from the palette
    const backgroundColor = pickWithRNGFrom(rng, palette);
    for (let i = 0; i < data.length; i += 4) {
      data[i + 0] = backgroundColor[0];
      data[i + 1] = backgroundColor[1];
      data[i + 2] = backgroundColor[2];
      data[i + 3] = 255;
    }


    const count = pickIntBetweenWithRNG(rng, axiomCountMin, axiomCountMax);

    for (let i = 0; i < count; i++) {
      // Generate a random axiom by picking 5 random characters from the set of valid commands
      const axiom = (new Array(axiomInitialLength)).fill(0).map(() => pickWithRNGFrom(rng, VALID_COMMANDS)).join('');

      const rules: { [key: string]: string } = {};
      const numberOfRules = pickIntBetweenWithRNG(rng, ruleCountMin, ruleCountMax);

      for (let ii = 0; ii < numberOfRules; ii++) {
        const key = pickWithRNGFrom(rng, VALID_COMMANDS);
        // Value length is a number between 5 and 10
        const valueLength = pickIntBetweenWithRNG(rng, ruleValueLengthMin, ruleValueLengthMax);
        let value = (new Array(valueLength)).fill(0).map(() => pickWithRNGFrom(rng, VALID_COMMANDS)).join('');
        rules[key] = value;
      }

      const result = runLindenmayer(
        axiom,
        rules,
        pickIntBetweenWithRNG(rng, axiomIterationCountMin, axiomIteratinCountMax)
      );
      drawLindenmayer(
        result,
        palette[Math.floor(rng() * palette.length)],
        Math.floor(rng() * width),
        Math.floor(rng() * height),
        Math.floor(rng() * 4),
        imageData,
      );
    }
    return imageData;
  }
};

export const lindenmayerToImageData: CanvasGeneratorFunction = lindenmayerMaker(
  {
    axiomCountMin: 20,
    axiomCountMax: 50,
    axiomInitialLength: 10,
    axiomIterationCountMin: 3,
    axiomIteratinCountMax: 10,
    ruleCountMin: 4,
    ruleCountMax: 7,
    ruleValueLengthMin: 2,
    ruleValueLengthMax: 6,
  }
);