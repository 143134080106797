import { useState } from "react";
import palettes from "./palettes";
import { generateSeed } from "./randomUtils";
import Painting from './Painting';
import SeedTextEdit from "./SeedTextEdit";
import { AlgoName, ALGOS } from "./generate";

const PaintingWithControls = () => {
  const paletteOptions = Object.keys(palettes);
  const algoOptions = Object.keys(ALGOS);
  const [seed, setSeed] = useState(generateSeed());
  const [algo, setAlgo] = useState(algoOptions[0]);
  const [palette, setPalette] = useState(paletteOptions[0]);
  const [width, setWidth] = useState(16);
  const [height, setHeight] = useState(16);
  const [magnification, setMagnification] = useState(24);

  const refreshSeed = () => {
    setSeed(
      generateSeed()
    )
  }

  return (
    <>
      <Painting
        seed={seed}
        algo={algo as AlgoName}
        palette={palette}
        width={width}
        height={height}
        magnification={magnification}
        onClick={refreshSeed}
      />
      <div>
        Width:
        <input

          type="number"
          value={width}
          onChange={(e) => {
            const width = e.target.valueAsNumber;
            setWidth(width);
          }}
        />
        Height:
        <input
          type="number"
          value={height}
          onChange={(e) => {
            const height = e.target.valueAsNumber;
            setHeight(height);
          }}
        />
        Magnification:
        <input
          type="number"
          value={magnification}
          onChange={(e) => {
            const magnification = e.target.valueAsNumber;
            setMagnification(magnification);
          }
          }
        />
        Palette:
        <select
          onChange={(e) => setPalette(e.target.value)}
        >
          {
            paletteOptions.map(p => (
              <option
                key={p}
                value={p}
                selected={p === palette}
              >
                {p}
              </option>
            ))
          }
        </select>
        Algo:
        <select
          onChange={(e) => setAlgo(e.target.value)}
        >
          {
            algoOptions.map(p => (
              <option
                key={p}
                value={p}
                selected={p === algo}
              >
                {p}
              </option>
            ))
          }
        </select>
        <SeedTextEdit seed={seed} onChange={setSeed} />
      </div>
    </>
  )

}

export default PaintingWithControls;