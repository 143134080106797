import { useEffect, useState } from "react";
import { flowers } from "./flowers";
import { makeNonce } from "./generate/common";
import { composeWFCState } from "./generate/wfc/composeImages";
import { WFCDefinitionList } from "./generate/wfc/solveWFC";
import { PaintingImg } from "./PaintingCommon";
import { PaintingMosaic } from "./PaintingMosaic";
import { pipes } from "./pipes";
import { requestWorker } from "./requestWorker";

const DEFINITIONS = {
  "pipes": pipes,
  "flowers": flowers,
};

export type PaintingWFCProps = {
  stateSize: [number, number],
  sendIntermediateStates?: boolean,
  state?: (string | undefined)[],
  width: number,
  height: number,
  renderType?: "img" | "mosaic",
}

export const PaintingWFC = ({
  stateSize,
  width,
  height,
  sendIntermediateStates = false,
  state = undefined,
  renderType = "img",
}: PaintingWFCProps) => {
  const [paintingContent, setPaintingContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [nonce, setNonce] = useState<string>(makeNonce(20));
  const [wfcDefinitions, setWfcDefinitions] = useState<WFCDefinitionList>(DEFINITIONS.pipes);
  const [resultState, setResultState] = useState<(string | undefined)[]>(
    state || Array(stateSize[0] * stateSize[1]).fill(undefined)
  );

  // Subscribe to the worker's message event
  useEffect(() => {
    const worker = requestWorker();
    setLoading(true); // Reset loading state

    const handleMessage = async (event: MessageEvent) => {
      if (event.data.nonce === nonce) {
        setResultState(
          event.data.state,
        );
        if (renderType === "img") {
          const image = await composeWFCState(wfcDefinitions, stateSize, event.data.state);
          setPaintingContent(image);
        }
        setLoading(false);
      }
    };

    worker.addEventListener("message", handleMessage);
    // Send message to worker
    worker.postMessage({
      type: "wfc",
      wfcDefinitions: wfcDefinitions,
      stateSize,
      state: state || Array(stateSize[0] * stateSize[1]).fill(undefined),
      workerOptions: { sendIntermediateStates },
      wfcOptions: {
        allowIdenticalNeighbors: false,
      },
      nonce,
    });

    return () => {
      worker.removeEventListener("message", handleMessage);
      // worker.terminate();
    }
  }, [nonce, stateSize, wfcDefinitions, renderType]);

  return (
    <div>
      {
        renderType === "img" ? (
          <PaintingImg
            src={paintingContent || ""}
            width={width}
            height={height}
            aspectRatio={width / height}
            onClick={() => setNonce(makeNonce(20))}
          />
        ) : (
          <PaintingMosaic
            stateSize={stateSize}
            state={resultState}
            width={width}
            height={height}
            wfcDefinitions={wfcDefinitions}
          />
        )

      }
    </div>
  );
}